.back-to-top {
    position: fixed;
    bottom: 128px;
    right: 3.8rem;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #333;
    transition: transform 0.58s ease-in-out;
    z-index: 1000;
}

.back-to-top img {
    width: 58px;
    height: auto;
    display: block;
    margin-bottom: 0%;
}

.back-to-top span {
    top: 1rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.892);
}