html {
    scroll-behavior: smooth;
}

.navbar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 738px;
    margin: 0 auto;
    padding-top: 1rem;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

.navbar-logo {
    max-width: 88%;
    height: auto;
    margin-bottom: -3.68rem;
}

.navbar {
    width: 100%;
    padding: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.88);
    border-radius: 1.28em;
    box-shadow: 0 16px 32px rgba(217, 197, 46, 0.88);
    transition: all 0.68s ease-in-out;
}

.navbar-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.navbar-item {
    flex: 1;
    text-align: center;
}

.navbar-item a {
    color: beige;
    text-decoration: none;
    font-size: 1.88rem;
    display: inline-block;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    font-weight: 100;
    padding: 0rem 1rem;
    border-radius: 0.8rem;
    margin-left: 1.5px;
    margin-right: 1.5px;
    line-height: 1;
}

.navbar-item a:hover {
    background-color: #f1eb45;
    transform: scale(0.88);
    color: rgba(0, 0, 0, 1);
    box-shadow: 0 2px 4px rgba(75, 81, 83, 0.588);
    border-radius: 0.38rem;
    animation: bounce 0.6s infinite;
}

.social-icons {
    position: absolute;
    top: 1.2rem;
    right: 2.4rem;
    padding: 0.2rem;
    display: flex;
    gap: 1rem;
    z-index: 100;
    background: rgba(0, 0, 0, 0.68);
    border-radius: 0.8em;
    box-shadow: 0 4px 8px rgba(87, 86, 86, 0.986);
}

.social-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.05rem;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
}

.social-icons a:hover {
    background-color: rgb(0, 0, 0, 1);
    color: rgb(248, 227, 35);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transform: rotate(360deg);
}

.icon {
    width: 2rem;
    height: 2rem;
}

@media (max-width: 768px) {
    .navbar {
        width: 90%;
        bottom: 1rem;
    }

    .navbar-item a {
        font-size: 1rem;
        padding: 0.5rem;
    }

    .navbar-logo {
        width: 15%;
        height: auto;
        max-height: 40px;
    }

    .navbar-item .icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}

@media (max-width: 480px) {
    .navbar {
        width: 95%;
    }

    .navbar-item a {
        font-size: 0.9rem;
        padding: 0.3rem;
    }

    .navbar-item .icon {
        width: 1rem;
        height: 1rem;
    }
}

@media (max-height: 600px) {
    .navbar {
        bottom: 1rem;
    }

    .navbar-item a {
        font-size: 1rem;
        padding: 0.5rem;
    }

    .navbar-item .icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}