#Mint {
    height: 100vh;
    flex-direction: column;
    font-weight: 100;
    font-style: italic;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: linear-gradient(to right,
            rgba(255, 0, 0, 0.9),
            rgba(255, 165, 0, 0.9),
            rgba(255, 255, 0, 0.9),
            rgba(0, 128, 0, 0.9),
            rgba(0, 0, 255, 0.9),
            rgba(75, 0, 130, 0.9));
    top: 0.88rem;
    border-radius: 0rem;
    padding: 2rem;
    border: none;
    box-shadow: none;
    margin: 0rem;
    text-align: center;
    animation: fadeIn 0.518s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: visible;
    margin-bottom: 18rem;
    border-top: 28px solid rgb(227, 203, 81);
    border-left: 18px solid rgb(238, 229, 55);
    border-right: 18px solid rgba(238, 229, 55);
    border-bottom: 28px solid rgb(227, 203, 81);
}

#Mint::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 1, 12, 0.88);
    backdrop-filter: blur(8px);
    border-radius: 0rem;
    z-index: -1;
}

.Mint-container {
    flex: 1;
    margin: 0 0.5rem;
    padding: 1rem;
    border-radius: 2rem;
    color: #ADD8E6;
    transition: max-height 0.5s ease-in-out, background-color 0.3s ease-in-out;
    overflow: hidden;
}

#Mint h4 {
    font-size: 2.5rem;
    text-align: center;
    color: #e2d10f;
    text-shadow: 0px 1px 2px rgba(217, 197, 46, 0.128);
    text-transform: uppercase;
    font-weight: 100;
    font-style: italic;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.mint-image {
    width: 100%;
    height: auto;
    max-width: 800px;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
    margin-top: 10rem;
    margin-bottom: 10rem;
}