html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  overflow: visible;
}

.background {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 118%;
  height: 118%;
  background: url('./assets/CivilBG.webp') no-repeat center center;
  background-size: cover;
  z-index: -2;
  transform: translate(-50%, -50%);
  transition: transform 0.168s ease-out;
}

.matte-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0);
  z-index: -1;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  overflow: visible;
}

section {
  font-size: 2rem;
  padding: 1rem;
  text-align: center;
  position: relative;
  z-index: 20;
  flex: 1;
  border-radius: 2rem;
  width: 80%;
  max-width: 68.8%;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.scroll-container {
  width: 100%;
  overflow: hidden;
  margin-top: 2.18rem;
  margin-bottom: 0.5168rem;
  position: relative;
  padding: 0.5rem 0;
}

.scroll-content {
  display: flex;
  width: 200%;
  animation: scroll 10s linear infinite;
}

.scroll-content img {
  margin-right: 2rem;
  width: auto;
  height: 38px;
}

@keyframes scroll {
  100% {
    transform: translateX(0%);
  }

  0% {
    transform: translateX(-90%);
  }
}

.divider-line {
  width: 100%;
  height: 1.68px;
  background-color: goldenrod;
  margin: 2rem 0;
}

.not-supported {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  font-size: 2rem;
  text-align: center;
}

.not-supported .logo {
  height: 120px;
  margin-bottom: 18px;
}

.not-supported p {
  margin: 0.5rem 0;
  font-size: 3rem;
  color: #FFD700;
  font-weight: 100;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.music-player {
  position: fixed;
  bottom: 1rem;
  right: 2.5rem;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.5168);
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  z-index: 100;
  transition: transform 0.3s ease, opacity 0.3s ease;
  overflow: visible;
}

.music-player:hover {
  transform: scale(1.2);
  opacity: 2;
}

.music-player .album-art-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 0.5rem;
  overflow: visible;
}

.music-player .album-art {
  width: 180px;
  height: 180.5px;
  border-radius: 10px;
  z-index: 2;
  position: absolute;
  top: -92px;
  left: -68px;
  transform: translate(0%, 0%);
}

.music-player .music-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.music-player .song-title {
  font-size: 1.2rem;
  font-weight: 388;
  font-style: italic;
  color: black;
}

.music-player .artist-name {
  font-size: 1rem;
  font-weight: 388;
  color: black;
  font-style: italic;
}

.music-player .play-pause-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.terms {
  position: fixed;
  font-size: 1.2rem;
  bottom: 1rem;
  left: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: right;
  background: rgba(255, 255, 255, 0.5168);
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1rem;
  z-index: 100;
  transition: transform 0.3s ease, opacity 0.3s ease;
  overflow: visible;
}

.terms:hover {
  transform: scale(1.2);
  opacity: 2;
}

.terms-link {
  color: #0e0c0c;
  text-decoration: none;
  transition: color 0.518s ease;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.terms-link:hover {
  color: #FFD700;
  text-decoration: underline;
}

.copyright {
  color: #0e0c0c;
  font-style: italic;
  font-weight: 600;
}

@media (max-width: 1200px) {
  section {
    font-size: 2.5rem;
    padding: 1.5rem;
  }
}

@media (max-width: 1024px) {
  section {
    font-size: 2rem;
    padding: 1rem;
  }
}

@media (max-height: 800px) {
  section {
    font-size: 2rem;
    padding: 1rem;
  }
}

@media (max-height: 600px) {
  section {
    font-size: 1.5rem;
    padding: 0.5rem;
  }
}

@media (max-width: 768px) {
  section {
    font-size: 1.5rem;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  section {
    font-size: 1.2rem;
    padding: 0.3rem;
  }

  .back-to-top {
    bottom: 1rem;
    left: 0.5rem;
  }

  .back-to-top img {
    width: 40px;
  }

  .back-to-top p {
    font-size: 0.7rem;
  }
}

@media (max-height: 480px) {
  section {
    font-size: 1.2rem;
    padding: 0.3rem;
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.preloader-logo {
  width: 688px;
  height: auto;
  margin-bottom: 20px;
}

.preloader-text {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  animation: pulse 1.5s infinite;
}

.progress-bar-container {
  width: 28%;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.progress-bar {
  height: 100%;
  background-color: #FFD700;
  width: 0;
  transition: width 0.3s ease;
  box-shadow: 0 0 10px #FFD700, 0 0 20px #FFD700, 0 0 30px #FFD700;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}