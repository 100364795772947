#Civil {
    height: 100vh;
    flex-direction: column;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: linear-gradient(to right,
            rgba(255, 0, 0, 0.9),
            rgba(255, 165, 0, 0.9),
            rgba(255, 255, 0, 0.9),
            rgba(0, 128, 0, 0.9),
            rgba(0, 0, 255, 0.9),
            rgba(75, 0, 130, 0.9));
    top: 0.88rem;
    border-radius: 0rem;
    padding: 2rem;
    border: none;
    box-shadow: none;
    margin: 0rem;
    text-align: center;
    animation: fadeIn 0.518s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: visible;
    margin-bottom: 18rem;
    border: 10px solid;
    border-top: 28px solid rgb(243, 52, 14);
    border-left: 18px solid rgb(177, 40, 18);
    border-right: 18px solid rgb(177, 40, 18);
    border-bottom: 28px solid rgba(243, 52, 14);
}

#Civil::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 1, 12, 0.88);
    backdrop-filter: blur(8px);
    border-radius: 0rem;
    z-index: -1;
}

.civil-content {
    text-align: center;
    color: #d6e0d6;
    max-width: 1678px;
    margin: 0 auto;
    text-shadow: 0px 2px 4px rgba(217, 197, 46, 0.128);
}

.civil-content h1 {
    font-size: 1.875rem;
    color: rgb(248, 227, 35);
    font-style: italic;
    font-weight: bolder;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.civil-content h2 {
    font-size: 1.888vw;
    margin-bottom: 1rem;
    color: rgb(248, 227, 35);
    font-style: italic;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.civil-content p {
    font-size: 1.58rem;
    font-style: italic;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: whitesmoke;
}

#Civil .Civil-title-image {
    width: auto;
    height: 8rem;
    object-fit: cover;
    animation: flicker 1.5s infinite alternate, shake 0.5s infinite;
    transform: rotate(6deg) translate(30%, 5%);
}

#Civil .image-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
}

#Civil .image-gallery img {
    width: calc(25% - 1rem);
    max-width: 300px;
    height: auto;
    border-radius: 8px;
}

#Civil .ityped-text {
    font-size: 3.168rem;
    color: rgb(248, 227, 35);
    text-shadow: 0px 0px 16px rgba(227, 214, 100, 0.898);
}

.ityped-cursor {
    opacity: 1;
    animation: blink 0.5s infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@keyframes flicker {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    20% {
        opacity: 0.8;
        transform: scale(1.02);
    }

    40% {
        opacity: 1;
        transform: scale(1);
    }

    60% {
        opacity: 0.7;
        transform: scale(1.03);
    }

    80% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0.9;
        transform: scale(1.01);
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -3px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -8px) rotate(-1deg);
    }
}