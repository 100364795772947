#Road {
    height: 100vh;
    flex-direction: column;
    font-style: italic;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: linear-gradient(to right,
            rgba(255, 0, 0, 0.9),
            rgba(255, 165, 0, 0.9),
            rgba(255, 255, 0, 0.9),
            rgba(0, 128, 0, 0.9),
            rgba(0, 0, 255, 0.9),
            rgba(75, 0, 130, 0.9));
    top: 0.88rem;
    bottom: 28rem;
    border-radius: 0rem;
    padding: 2rem;
    border: none;
    box-shadow: none;
    margin: 0rem;
    text-align: center;
    animation: fadeIn 0.518s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: visible;
    margin-bottom: 18rem;
    border-top: 28px solid rgb(65, 156, 67);
    border-left: 18px solid rgb(52, 224, 0);
    border-right: 18px solid rgb(52, 224, 0);
    border-bottom: 28px solid rgb(65, 156, 67);
}

#Road::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 1, 12, 0.88);
    backdrop-filter: blur(8px);
    z-index: -1;
}

.Road-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;

}

.Road-container h2,
h3 {
    font-size: 2.5rem;
    text-align: center;
    color: #e2d10f;
    text-shadow: 0px 1px 2px rgba(217, 197, 46, 0.128);
    text-transform: uppercase;
    font-style: italic;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Road-phase {
    margin: 0 0.5rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.168);
    box-shadow: 0 8px 16px rgba(234, 202, 23, 0.5168);
    color: whitesmoke;
    max-height: auto;
    overflow: visible;
}

.phase-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.text-content1 {
    flex: 2;
    margin-right: 1rem;
}

.image-content1,
.image-content2,
.image-content3 {
    flex: 1;
    text-align: center;
}

.image-content1 img {
    width: 80%;
    height: auto;
    border-radius: 0.5rem;
    transform: rotate(8deg);
    border: 3px solid rgba(255, 215, 0, 0.88);
    box-shadow: 0 6px 28px rgba(0, 0, 0, 0.8);

    margin-bottom: 1.29rem;
    margin-top: 1.69rem;
}

.Road-phase .phase-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #FFD700;
    text-shadow: 2px 2px 4px rgba(217, 197, 46, 0.8);
}

.Road-phase h6 {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #e2d10f;
    text-shadow: 2px 2px 4px rgba(217, 197, 46, 0.68);
}

.Road-phase p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-style: italic;
    font-weight: bolder;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.28rem;
    line-height: 1.28;
    color: whitesmoke;
    text-shadow: 0px 1px 2px rgba(217, 197, 46, 0.128);
    text-align: center;
}

.Road-phase p span.percent {
    display: flex;
    font-weight: bold;
    color: #e2d10f;
    text-shadow: 2px 2px 4px rgba(217, 197, 46, 0.68);
    margin-left: 2rem;
    text-align: left;
}

.Road-phase p span.text {
    display: flex;
    margin-left: 1rem;
    text-align: left;
    margin-left: 2rem;
}