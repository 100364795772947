#Team {
    height: 100vh;
    flex-direction: column;
    font-style: italic;
    font-weight: 100;
    font-family: 'Nunito Sans', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: linear-gradient(to right,
            rgba(255, 0, 0, 0.9),
            rgba(255, 165, 0, 0.9),
            rgba(255, 255, 0, 0.9),
            rgba(0, 128, 0, 0.9),
            rgba(0, 0, 255, 0.9),
            rgba(75, 0, 130, 0.9));
    top: 0.88rem;
    border-radius: 0rem;
    padding: 2rem;
    border: none;
    box-shadow: none;
    margin: 0rem;
    text-align: center;
    animation: fadeIn 0.518s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: visible;
    margin-bottom: 18rem;
    border-top: 28px solid rgb(55, 89, 238);
    border-left: 18px solid rgba(44, 136, 235);
    border-right: 18px solid rgba(44, 136, 235);
    border-bottom: 28px solid rgb(55, 89, 238);
}

#Team::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 1, 12, 0.88);
    backdrop-filter: blur(8px);
    border-radius: 0rem;
    z-index: -1;
}

#Team h5 {
    font-size: 2.5rem;
    text-align: center;
    color: #e2d10f;
    text-shadow: 0px 1px 2px rgba(217, 197, 46, 0.128);
    text-transform: uppercase;
    font-weight: 100;
    font-style: italic;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#Team .team-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2.8rem;
    margin-bottom: 2.8rem;
}

#Team .team-text {
    flex: 1;
    margin-left: 2rem;
    color: white;
}

#Team .team-text h6 {
    margin: 0.5rem 0;
    color: #FFD700;
    font-weight: bold;
    text-align: left;
    font-style: italic;
}

#Team .team-text p {
    color: whitesmoke;
    text-shadow: 0px 1px 2px rgba(217, 197, 46, 0.128);
    text-align: left;
    font-size: 1.28rem;
    font-style: italic;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    line-height: 1;
}

.Team-all-container {
    flex: 1;
    margin: 0 0.5rem;
    padding: 1rem;
    border-radius: 2rem;
    background-color: rgba(0, 0, 0, 0.168);
    box-shadow: 0 8px 16px rgba(234, 202, 23, 0.5168);
    color: whitesmoke;
    transition: max-height 0.5s ease-in-out, background-color 0.3s ease-in-out;
    overflow: hidden;
}

#Team .team-images-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto;
    row-gap: 2rem;
    column-gap: 5.168rem;
    justify-items: center;
    align-items: center;
}

#Team .team-images-grid .corner-image {
    width: 140px;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#Team .team-images-grid .top-left {
    grid-area: 1 / 1;
}

#Team .team-images-grid .top-right {
    grid-area: 1 / 2;
}

#Team .team-images-grid .bottom-left {
    grid-area: 3 / 1;
    margin-top: -0.8rem;
}

#Team .team-images-grid .bottom-right {
    grid-area: 3 / 2;
    margin-top: -0.8rem;
}

#Team .team-images-grid .center-gif {
    grid-column: 1 / -1;
    grid-row: 2;
    width: 100%;
    max-width: 140px;
    height: auto;
    border-radius: 1rem;
    justify-self: center;
}

#Team .team-images-grid .center-gif-container {
    position: relative;
    grid-column: 1 / -1;
    grid-row: 2;
    width: 100%;
    max-width: 140px;
    height: auto;
}



#Team .team-images-grid .overlay {
    position: absolute;
    top: -0.6px;
    left: -1px;
    right: -1px;
    bottom: 10.168px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

#Team .team-images-grid .overlay-text {
    font-size: 5.168rem;
    color: #FFD700;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8);
    font-weight: bold;
}

#Team .team-images-grid .center-gif-container:hover .overlay {
    opacity: 0;
}