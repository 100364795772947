#Mission {
    height: 100vh;
    flex-direction: column;
    font-style: italic;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: linear-gradient(to right,
            rgba(255, 0, 0, 0.9),
            rgba(255, 165, 0, 0.9),
            rgba(255, 255, 0, 0.9),
            rgba(0, 128, 0, 0.9),
            rgba(0, 0, 255, 0.9),
            rgba(75, 0, 130, 0.9));
    top: 0.88rem;
    border-radius: 0rem;
    padding: 2rem;
    margin: 0rem;
    animation: fadeIn 0.518s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: visible;
    margin-bottom: 18rem;
    border-top: 28px solid rgb(252, 129, 57);
    border-left: 18px solid rgba(194, 102, 27);
    border-right: 18px solid rgba(194, 102, 27);
    border-bottom: 28px solid rgb(252, 129, 57);
}

#Mission::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 1, 12, 0.88);
    backdrop-filter: blur(8px);
    border-radius: 0rem;
    z-index: -1;
}

#Mission h2 {
    font-size: 2.5rem;
    text-align: center;
    color: #e2d10f;
    text-shadow: 0px 1px 2px rgba(217, 197, 46, 0.128);
    text-transform: uppercase;
    font-style: italic;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.mission-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
}

.mission-text {
    padding: 1rem;
    font-size: 1.58rem;
    font-style: italic;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: whitesmoke;
}

.mission-image {
    flex: 0.9;
    display: flex;
    justify-content: center;
}

.mission-final img {
    width: 88%;
    height: auto;
    border-radius: 2rem;
    box-shadow: 0 4px 8px rgba(234, 202, 23, 0.5168);
}

.mission-container {
    flex: 1;
    margin: 0 1rem;
    padding: 1rem;
    border-radius: 2rem;
    background-color: rgba(0, 0, 0, 0.168);
    box-shadow: 0 8px 16px rgba(234, 202, 23, 0.5168);
    transition: max-height 0.5s ease-in-out, background-color 0.3s ease-in-out;
    overflow: hidden;
}